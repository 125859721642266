import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useWindowWidth } from "../../../services/hooks"
import NewButton from "./NewButton"
import { navigate } from 'gatsby';
import usePromo from "../../../utils/usePromo";

const Hero = ({ isHydroSkyhy, isAvg }) => {
  const [isMobile, setIsMobile] = useState()
  const windowWidth = useWindowWidth()
  const isPromo = usePromo()

  const handleGaClick = () => {
    if(typeof window !== "undefined"){
      navigate(window.location.search + (isAvg ? "#lenses-section" : `#order-section`))
    }
  }

  useEffect(() => {
    setIsMobile(windowWidth < 768)
  }, [windowWidth])

  return (
    <section className={`${isHydroSkyhy ? "fb-hero-hydro-skyhy-v3" : 'fb-hero-v3'} ${isAvg ? "avg" : ""}`}>
      <div className={`content-container-hero-v3 ${isPromo ? "promo" : ""}`}>
        <div className='text-block-v3'>
          <div className='text-container-v3'>
            {
              isPromo ?
                <>
                  <h2 className="hero-subheader-v3">limited time only</h2>
                  <h1 className='hero-title-v3 promo'>Summer Sale</h1>
                  {isMobile ?
                    <p className='hero-subtitle-v3 promo'>Get your first order of Hydro by Hubble for <b>$4.99/eye,</b> or
                      SkyHy by Hubble for <b>$9.99/eye.</b></p>
                    :
                    <p className='hero-subtitle-v3 promo'>Get your first order of Hydro by Hubble for just <b>$4.99/eye,</b> <br/>or
                      SkyHy by Hubble for <b>$9.99/eye.</b></p>
                  }
                </>
              :
              isMobile
                ? <>
                  <h1 className='hero-title-v3'>{<>Start now for <br/> as little as $1.</>}</h1>
                  <p className='hero-subtitle-v3'>We make contacts easy.</p>
                </>
                : <>
                  <p className='hero-subtitle-v3'>We make contacts easy.</p>
                  <h1 className='hero-title-v3'>{<>Start now for <br/> as little as $1.</>}</h1>
                </>}
            {
              isMobile
                ? <NewButton
                  label={"Start Your Subscription"}
                  color={"purple"}
                  onClick={() => handleGaClick()}
                  disabled={false}
                  className={'mobile-only start-button start-button-v3'}
                  stickyScroll />
                : <a href={isAvg ? "#lenses-section" : '#order-section'} className='start-button-v3'>
                  {isHydroSkyhy ? "Start Your Subscription" : "Start for $1"}
                </a>
            }
          </div>
        </div>
        {
          isMobile
            ? null
            : <div className='image-block-v3'>
              <div className='image-v3' />
            </div>
        }
      </div>
    </section>
  );
};

export default Hero;
