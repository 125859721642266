import React, { useState, useContext, useEffect} from "react"

import "./style.scss"
import HubbleOrderContext from '../../../context/hubbleOrderContext';
import WebpImage from "../../WebpImage"
import NewButton from "../../NewButton";
import {useWindowWidth} from "../../../services/hooks";

const ContactsCartProductItem = ({product, onBuy, promoLabel}) => {
  const windowWidth = useWindowWidth()
  const [isMobile, setIsMobile] = useState(false)
  const { setBrandSelected, setBrand, setTaxes, setShippingAddress} = useContext(
    HubbleOrderContext
  )
  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    if (windowWidth > 768) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }
  }, [windowWidth])


const setProduct = (value) => {
  // this will toggle the display of the rx section
  setBrandSelected(true)
  // now we need to go ahead and set the context so the rx values grab the right amount
  setBrand({...value, isAccessory: true});
  setTaxes(0)
  setShippingAddress({
    first_name: "",
    last_name: "",
    address1: "",
    address2: "",
    city: "",
    province: "",
    zipcode: "",
    phone: "",
  })

  // google datalayer events for select item
  window.dataLayer.push({ecommerce: null})
  window.dataLayer.push({
    event: "select_item",
    ecommerce: {
      items: [
        {
          item_id: value.cc_product_id || '2167100637257',
          index: 0,
          item_name: value.title,
          currency: "USD",
          discount: 0,
          item_brand: value.title.includes("Hubble") ? "Hubble" : value.title,
          item_category: "contacts",
          item_category2: 'spherical',
          item_category3: 'N/A', //gender
          item_category4: 'N/A',
          item_category5: 'N/A',
          item_list_id: "Normal",
          item_variant: 'N/A',
          price: parseFloat(value.sale_price)
        }
      ]
    }
  })



  // scroll into view of rx section
  setTimeout(() => {
    const section = document.querySelector( '#order-section' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  }, 500);
}

  const handleBuy = () => {
    onBuy ? onBuy(product) : setProduct(product)
  }

  return (
    <div className="product-list-item"
    onMouseEnter={() => {setIsHovered(true)}}
    onMouseLeave={() => {setIsHovered(false)}}
    onClick={!isMobile && handleBuy}
    id={product.value}
    >
      <div className="product-image-container">
        {!isMobile && <div className="hover-checker"></div>}
        <WebpImage className='press-image' fileName={`Pages/Intake/Products/${product.image}.png`} />
        {isHovered && !isMobile && <>
          <WebpImage className='press-image hovered' fileName={`Pages/Intake/ProductsHover/${product.image}-02.png`}/>
          <NewButton color="black" className="buy-btn" label="Buy Now"/>
        </>}

        {isMobile && <p className="quantity">{product.quantity} pack</p>}
        {!isMobile && promoLabel && <div className="promo-tag">{promoLabel}</div>}
      </div>

      <div className="details">
        <p className="title">{product.title}</p>
        {!isMobile && <p className="quantity">{product.quantity} pack</p>}
        <div className="price-container">
          <div className="price-wrapper">
            {product.sale_price && <p className="sale-price">${product.price}/eye</p>}
            <p className="price">${product.sale_price || product.price}/eye</p>
          </div>
          <p className="percent-off">{product.percent_off}% OFF</p>
        </div>
        {isMobile && <a className="buy-btn" onClick={handleBuy}>Buy Now</a>}
      </div>
    </div>
  )
}

export default ContactsCartProductItem
